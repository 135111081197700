<template>
  <div class="page">
    <div class="page-title">
      <img @click="$router.push('/')" src="@/assets/images/back.svg">
      {{$route.meta.routeTitle}}
    </div>


    <div class="paragr">К таким отходам относятся строительные материалы и отходы, образованные при санитарной очистке территорий (от субботников), содержащие в себе растительные отходы. Полный перечень указан в Федеральном классификационном каталоге отходов, утвержденных приказом Федеральной службы по надзору в сфере природопользования от 22.05.2017 N 242.</div>
    <div class="paragr">Пример строительных отходов (не относятся к ТКО): битый кирпич, отходы бетона, отходы от сноса стен, разборки крыш, опор и оснований, железобетона и других отходов ремонта и реконструкции, спил деревьев, обрезанные ветки– вывозятся по отдельному договору.</div>
    <div class="paragr">Такой мусор должен вывозиться по отдельному договору специализированной организацией, в том числе с региональным оператором, по нерегулируемым ценам, за счет средств заявителя.</div>

    <h3>Предприятия, принимающие вторичные ресурсы</h3>
    <ul class="companies-list">

      <li>
        <div class="company-title">ООО МСК “Мохит-СТР”</div>
        <div class="row">
          <img src="@/assets/images/no-tko-location.svg">
          <span>г. Стерлитамак, ул. Элеваторная, 2А</span>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-tel.svg">
          <a href="tel:+79371600302">8 (937) 160 03 02</a>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-time.svg">
          <span>8:00-20:00 (без перерыва и выходных)</span>
        </div>
      </li>

      <li>
        <div class="company-title">ООО “Эко-Сити”</div>
        <div class="row">
          <img src="@/assets/images/no-tko-location.svg">
          <span>Ишимбайский р/н, Урман-Бишкадакский с/с, территория автодороги Ишимбай-Красноусольск, 5-ый км, здание 1</span>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-tel.svg">
          <a href="tel:+7 (927) 082 22 17">8 (927) 082 22 17</a>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-time.svg">
          <span>8:00-20:00 (без перерыва и выходных)</span>
        </div>
      </li>

      <li>
        <div class="company-title">ООО “Управление отходами Мелеуз”</div>
        <div class="row">
          <img src="@/assets/images/no-tko-location.svg">
          <span>Мелеузовский район, территория Арслановский полигон ТКО, строение 1</span>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-tel.svg">
          <a href="tel:+7 (917) 352 15 86">8 (917) 352 15 86</a>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-time.svg">
          <span>8:00-18:00 (без перерыва и выходных)</span>
        </div>
      </li>

      <li>
        <div class="company-title">ООО “НУР”</div>
        <div class="row">
          <img src="@/assets/images/no-tko-location.svg">
          <span>Отработанный Мурдашевский карьер кирпичных глин», располагается по адресу: Мелеузовский район, ориентир в границах отработанного карьера Мурдашевского месторождения кирпичных глин</span>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-tel.svg">
          <a href="tel:+7 (917) 352 15 86">8 (917) 352 15 86</a>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-time.svg">
          <span>8:00-20:00 (без перерыва и выходных)</span>
        </div>
      </li>

      <li>
        <div class="company-title">ООО “Экоиндустрия” </div>
        <div class="row">
          <img src="@/assets/images/no-tko-location.svg">
          <span>Аургазинский район, 1250 м. северо-западнее с. Толбазы</span>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-tel.svg">
          <a href="tel:+7 (927) 081 44 74">8 (927) 081 44 74</a>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-time.svg">
          <span>8:00-18:00 (без перерыва и выходных)</span>
        </div>
      </li>

      <li>
        <div class="company-title">ООО “Сервис-Уют” </div>
        <div class="row">
          <img src="@/assets/images/no-tko-location.svg">
          <span>4.8 км южнее г. Белорецка и 800 м. От автодороги Стерлитамак -Магнитогорск</span>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-tel.svg">
          <a href="tel:+7 (34792) 4 75 26">8 (34792) 4 75 26</a>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-time.svg">
          <span>8:00-20:00 (без перерыва и выходных)</span>
        </div>
      </li>

      <li>
        <div class="company-title">ООО “Чистый город+” </div>
        <div class="row">
          <img src="@/assets/images/no-tko-location.svg">
          <span>Учалинский район, СП Имангуловский сельсовет, в 8,6 км на северо-восточном направлении от д.Урал</span>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-tel.svg">
          <a href="tel:+7 (927) 932 19 01">8 (927) 932 19 01</a>
        </div>
        <div class="row">
          <img src="@/assets/images/no-tko-time.svg">
          <span>8:00-20:00 (без перерыва и выходных)</span>
        </div>
      </li>

    </ul>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
.page-title{
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
  padding-left: 0;
  img{
    margin-right: 10px;
    margin-top: 3px;
    cursor: pointer;
  }
}

.paragr{
  margin-bottom: 29px;
  font-size: 16px;
  line-height: 28px;
  font-family: 'TT Norms Pro Regular';
}

h3{
  font-family: "TT Norms Pro Regular";
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 36px;
}

.companies-list{
  gap: 16px 0;
  display: flex;
  flex-direction: column;

  li{
    padding: 16px;
    background: white;
    border-radius: 16px;

    .company-title{
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 16px;
      line-height: 28px;
    }
    .row{
      display: flex;
      margin-bottom: 11px;
      &:last-child{
        margin-bottom: 0;
      }
      img{
        margin-right: 10px;
        width: 24px;
        height: 24px;
      }
      span, a{
        font-size: 16px;
        line-height: 22px;
        font-family: "TT Norms Pro Regular";
        text-decoration: none;
      }
    }
  }
}
</style>
